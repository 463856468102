exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---coloreshtml-template-color-page-js": () => import("./../../../coloreshtml/template/ColorPage.js" /* webpackChunkName: "component---coloreshtml-template-color-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-color-de-la-suerte-hoy-js": () => import("./../../../src/pages/color-de-la-suerte-hoy.js" /* webpackChunkName: "component---src-pages-color-de-la-suerte-hoy-js" */),
  "component---src-pages-en-lucky-color-today-js": () => import("./../../../src/pages/en/lucky-color-today.js" /* webpackChunkName: "component---src-pages-en-lucky-color-today-js" */),
  "component---src-pages-fr-couleur-de-la-chance-aujourd-js": () => import("./../../../src/pages/fr/couleur-de-la-chance-aujourd.js" /* webpackChunkName: "component---src-pages-fr-couleur-de-la-chance-aujourd-js" */),
  "component---src-pages-pt-cor-da-sorte-hoje-js": () => import("./../../../src/pages/pt/cor-da-sorte-hoje.js" /* webpackChunkName: "component---src-pages-pt-cor-da-sorte-hoje-js" */),
  "component---src-pages-tematicas-js": () => import("./../../../src/pages/tematicas.js" /* webpackChunkName: "component---src-pages-tematicas-js" */),
  "component---src-template-index-page-js": () => import("./../../../src/template/IndexPage.js" /* webpackChunkName: "component---src-template-index-page-js" */),
  "component---src-template-legal-page-js": () => import("./../../../src/template/LegalPage.js" /* webpackChunkName: "component---src-template-legal-page-js" */),
  "component---src-template-page-palette-color-js": () => import("./../../../src/template/PagePaletteColor.js" /* webpackChunkName: "component---src-template-page-palette-color-js" */),
  "component---src-template-page-tono-palette-js": () => import("./../../../src/template/PageTonoPalette.js" /* webpackChunkName: "component---src-template-page-tono-palette-js" */),
  "component---src-template-post-template-js": () => import("./../../../src/template/PostTemplate.js" /* webpackChunkName: "component---src-template-post-template-js" */),
  "component---src-template-scheme-template-js": () => import("./../../../src/template/SchemeTemplate.js" /* webpackChunkName: "component---src-template-scheme-template-js" */),
  "component---src-template-tematic-post-js": () => import("./../../../src/template/TematicPost.js" /* webpackChunkName: "component---src-template-tematic-post-js" */),
  "component---src-template-tool-template-js": () => import("./../../../src/template/ToolTemplate.js" /* webpackChunkName: "component---src-template-tool-template-js" */)
}

